<template>
    <div class="custom-content">
        <el-scrollbar class="custom-wrap" v-if="customList.length >0">
            <div class="top-btn">
                <div class="create-btn" @click="createLottery">添加抽奖</div>
            </div>
            <el-table class="customTable" :data="customList" style="width: 100%; flex: 1;" height="1%" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="name" label="基本信息" align="left"></el-table-column>
                <el-table-column prop="type" label="抽奖方式" align="center"></el-table-column>
                <el-table-column prop="people_num" label="抽取人数" align="center"></el-table-column>
                <el-table-column prop="remarks" label="备注" align="center">
                    <template slot-scope="scope">
                        <span class="remark">{{scope.row.remarks?scope.row.remarks:'暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="operation" label="状态" align="center">
                </el-table-column>
                <el-table-column prop="fileList" label="操作" align="center" width="400">
                    <template slot-scope="scope">
                        <div class="link-box">
                            <el-link type="success" :underline="false" @click="editLottery(scope.row)">
                                编辑
                            </el-link>
                            <el-link type="danger" :underline="false" @click="delLottery(scope.row)">
                                删除
                            </el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <pagination class="pages-center" :total="customInfo.total" @current-change="handleCurrentChange"/>
        </el-scrollbar>
        <div class="custom-wrap" v-else>
            <el-table class="customTable noData" :data="customList"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <template slot="empty">
                    <div class="no-data">
                        <div class="no-data-content">
                            <div class="img-box">
                                <img src="../../../../../assets/images/student/lottery-null-data.png">
                                <span class="no-data-title">暂无抽奖</span>
                            </div>
                            <div class="btn-box">
                                <div class="create-btn" @click="createLottery">创建抽奖</div>
                            </div>
                        </div>
                    </div>
                </template>
                <el-table-column prop="content" label="名称" align="left"></el-table-column>
                <el-table-column prop="status" label="抽奖方式" align="center"></el-table-column>
                <el-table-column prop="status" label="抽取人数" align="center"></el-table-column>
                <el-table-column prop="status" label="备注" align="center"></el-table-column>
                <el-table-column prop="status" label="状态" align="center"></el-table-column>
                <el-table-column prop="fileList" label="操作" align="center" width="200"></el-table-column>
            </el-table>
        </div>
        <addLotteryDialog v-if="addLotteryDialog" :addLotteryDialog="addLotteryDialog" @closeDialog="closeDialog"
                          @saveLottery="saveLottery" :editId="editId"/>
    </div>
</template>

<script>
    import pagination from "@/components/pagination";
    import addLotteryDialog from "@/components/student/train/addLotteryDialog";
    import {stuOpDrawList, stuOpSaveDraw, stuOpDrawDel} from "@/utils/apis"

    export default {
        name: "lotteryIndex",
        components: {
            pagination,
            addLotteryDialog
        },
        data() {
            return {
                customList: [],
                goodsList: [],
                customInfo: {
                    total: 0,
                    currentPageNum: 1,
                },
                addLotteryDialog: false,
                editId: '',
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
            }

        },
        created() {
            this.getLotteryList();
        },
        methods: {
            getLotteryList() {
                this.customList = [];
                this.editId = '';
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId,
                    page: this.customInfo.currentPageNum,
                    limit: 10
                }
                stuOpDrawList(param).then(res => {
                    if (res.data !== null) {
                        this.customList = res.data.list
                        this.customInfo.total = res.data.total
                    }
                }).catch(err => {
                    console.error(err)
                })
            },
            handleCurrentChange(val) {
                this.customInfo.currentPageNum = val;
            },
            createLottery() {
                this.addLotteryDialog = true
            },
            closeDialog(val) {
                this.addLotteryDialog = val
                this.editId = ''
            },
            saveLottery(val) {
                let param = val;
                param.op_id = this.opId;
                param.course_id = this.courseId;
                stuOpSaveDraw(param).then(res => {
                    this.$message.success(res.msg);
                    this.getLotteryList();
                }).catch(err => {
                    console.error(err)
                })
            },
            editLottery(row) {
                this.editId = row.id;
                this.addLotteryDialog = true
            },
            delLottery(row) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    stuOpDrawDel({id: row.id}).then((res) => {
                        this.getLotteryList();
                        this.$message.success(res.msg)
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .custom-content {
        margin-top: 18px;
        height: calc(100% - 70px);
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;

        .create-btn {
            width: 118px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #2DC079;
            border-radius: 4px;
            color: #2DC079;
            line-height: 40px;
            cursor: pointer;
            text-align: center;
            font-size: 16px;
        }

        .custom-wrap {
            flex: 1;
            height: 1%;
            display: flex;
            flex-direction: column;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                height: 100%;

                .el-scrollbar__view {
                    margin: 0 20px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
            }

            .customTable {
                margin-top: 12px;

                &.noData {
                    ::v-deep .el-table__body-wrapper {
                        width: 100%;
                        height: 100% !important;

                        .el-table__empty-block {
                            border: unset;
                            display: flex;
                            flex-direction: column;

                            .el-table__empty-text {
                                flex: 1;
                                height: 1%;
                            }
                        }
                    }
                }

                .no-data {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .no-data-content {
                        display: flex;
                        flex-direction: column;

                        .img-box {
                            display: flex;
                            flex-direction: column;

                            .no-data-title {
                                color: #999999;
                                font-size: 16px;
                            }
                        }

                        .btn-box {
                            display: flex;
                            justify-content: center;
                        }

                        .create-btn {
                            margin-top: 40px;
                        }
                    }
                }
            }

            .item-content {
                display: flex;
                align-items: center;

                .item-left {
                    width: 192px;
                    height: 108px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .item-right {
                    width: 192px;
                    height: 108px;
                    margin-left: 20px;

                    .item-title {
                        color: #333333;
                    }
                }
            }

            .pages-center {
                margin-bottom: 0;
            }
        }
    }
</style>